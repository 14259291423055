html[dir="rtl"] {
    body {
        direction: rtl;
        text-align: right;
    }
    .parent-order .dropdown-for-activity {
        padding-right: 37px;
    }
    .table-lib .texting-end {
        padding-left: 35px;
        padding-right: 0;
    }
    .table-lib .width-47 {
        padding-right: 12px;
        padding-left: 0;
    }
    .table-lib .padding-applied {
        padding-left: 0;
        padding-right: 20px;
    }
    .accountsHeader-binder .accounts-sub-drp {
        position: absolute;
        left: -128px;
        top: 23px;
        left: 0;
        right: auto;
        min-width: 170px;
        text-align: right;
    }
    .accountsHeader-binder .accounts-eachperson-logo {
        margin-left: 0;
        margin-right: 35px;
    }
    .accounts-admin-icon-drop {
        margin-left: 0;
        margin-right: 10px;
    }
    @media screen and (max-width: 767px) {
        .parent-order .dropdown-for-activity {
            padding-right: 0;
        }
        .dropdown-for-activity-sub-binder {
            padding-right: 15px;
        }
        .dropdown-for-activity-componet {
            margin-left: 5px;
        }
        .market-page-pref {
            padding-right: 14px;
        }
    }
    .ui.input>input{
        text-align: right;
    }
    .ui.modal{
        text-align: right;
    }
    .modal-cross{
        right: auto;
        left: 0;
    }
}
