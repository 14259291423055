.bg-yellow {
  border-color: transparent !important;
}
.border-color-changes {
  color: #ccc;
}
.border-green-removed {
  color: #a4a4a4 !important;
}
.border-green-remove {
  color: #a4a4a4 !important;
}
.stepper-child-fixes {
  right: -3px !important;
  color: #000 !important;
}
.stepper-parent-fixes {
  color: #a4a4a4 !important;
}
.font-differences {
  font-size: 14px !important;
  color: #a4a4a4;
}
.font-weights-stepper-spans {
  font-weight: 700 !important;
  color: #000 !important;
}
.stepper-circle-padding-changes {
  padding: 8px;
}

.stepper-changess {
  margin-left: 2%;
  padding-right: 4%;
}

.stepper-last-children {
  padding-right: 41px;
}
/* mobile */
@media screen and (min-width: 1px) and (max-width: 767px) {
  .stepper-changess > div:nth-child(2) .stepper-child-fixes {
    right: 8px !important;
  }
  .stepper-changess > div:nth-child(3) .stepper-child-fixes {
    right: 8px !important;
  }
}

@media (min-width: 767px) {
  .stepper-changess > div:nth-child(2) .stepper-child-fixes {
    right: 8px !important;
  }
  .stepper-changess > div:nth-child(3) .stepper-child-fixes {
    right: 8px !important;
  }
  .stepper-last-children {
    padding-right: 43px !important;
  }
}
.border-1 {
  border-width: 1px !important;
}
.border-t-1 {
  border-top-width: 1px !important;
}
