.salary-strips {
  padding-bottom: 48px;
}
.save-from-salary-accounts-wrapper {
  max-width: 1000px;
  margin: auto;
  border-radius: 10px;
  margin-top: 42px;
}
.heade-ss-text {
  font-size: 20px;
  line-height: normal;
  letter-spacing: -0.39px;
  padding-top: 2px;
}
.header-for-ss {
  padding-top: 27px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.font-14 {
  font-size: 14px;
}
.font-20 {
  font-size: 20px;
}
.ss-employee-form-sub-binder {
  padding-top: 25px;
  background-color: white;
  padding-bottom: 49px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ss-benifts-sub-para {
  display: flex;
  align-items: baseline;
}
.dot-with-para {
  padding-left: 23px;
}
.ss-emp-form-head {
  max-width: 50.5%;
  margin: auto;
}
.ss-emp-form-head-sub {
  padding-bottom: 9px;
}
.ss-emp-form {
  margin-top: 28px;
}
.ss-employee-form {
  padding-bottom: 35px;
}
.pot-carried-state {
  padding-top: 27px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 20px;
}
.modify-pot-para{
  text-align: justify!important;
}
.pot-carried-state .btn-edit-amount{
  padding-top: 14px;
  padding-bottom: 14px;
}
.table-desk-ac .ss-full-bal {
  padding-top: 25px;
}
.table-desk-for-accounts .dropdown-for-activity {
  padding-top: 17px;
}
.table-desk-sub-binder {
  max-width: 91.6%;
  margin: auto;
}
.table-desk-for-accounts {
  margin-top: 14px;
  padding-top: 20px;
  padding-bottom: 35px;
  background-color: white;
  border-radius: 10px;
}
.ss-full-bal-p {
  padding-top: 22px;
}
.ss-statement-binder {
  padding-bottom: 49px;
}
.save-salary-wrapper a:hover {
  color: unset !important;
}
/* dashboard */
.ss-dashboard-main {
  padding-bottom: 45px;
}
.ss-dashboard {
  background-color: white;
  padding-bottom: 24px;
}
.ss-dasboard-wrapper {
  padding-top: 23px;
  max-width: 49.5%;
  margin: auto;
}
.ss-dashboard .ss-dash-text-div {
  padding-top: 26px;
}
.ss-dashboard .pb-btm {
  padding-bottom: 13px;
}
.ss-dashboard .bnfs-text {
  padding-top: 30px;
  padding-bottom: 10px;
}
.ss-dashboard .ss-dashboard-bnf {
  padding-bottom: 27px;
}
.ss-dashboard .accordion-component {
  padding-bottom: 28px;
}
.ss-dashboard .dashboard-scss-btns {
  padding-top: 5px;
}

/* modal */
.terms-cons {
  padding-top: 14px;
}
.terms-mdl {
  padding-top: 21px;
}
.model-body-terms {
  padding-top: 12px;
  max-width: 89.8%;
  margin: auto;
}
.model-body-terms.max-height-fix{
  max-height: 257px;
  overflow-y: scroll;
} 
.terms-cons-modal {
  max-width: 39.2%;
  margin: auto;
}
.terms-cons-modal .p-for-second {
  padding-top: 21px;
}
.model-body-terms-sub p {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.27px;
}
.terms-cons-btn {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 35px;
}
.terms-cons-modal .modal-cross {
  top: 8px;
}
.modify-states .pot-carried-state{
  padding-bottom: 28px;
}
.manage-account-sub{
  padding-top: 27px;
}
.clickable-txt p{
  padding-top: 10px;
}
.manage-account-wrapper .warn-text{
  padding-top: 7px;
}
.manage-account-wrapper .go-back-btn{
  padding-top: 24px;
  padding-bottom: 35px;
}
.manage-account-wrapper{
  border-radius: 10px;
}
.pause-mnt-modal{
  max-width: 560px;
}
.terms-cons{
  text-align: justify;
}
.pause-mdl-sub-heed{
  max-width: 89.8%;
  margin: auto;
}
.hadnle-btns-mng{
    max-width: 504px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 34px;
}

.pause-mnt-modal .modal-cross{
  top: 8px;
}
.withdraw-form-main-wrap{
  padding-top: 46px;
  padding-bottom: 35px;
  border-radius: 10px;
}
.with-draw-form{
  padding-bottom: 42px;
}
.text-black-acount{
  color: #212322!important;
}

.body-of-salry .breadcrumb{
  padding-top: 0;
}
.terms-cons-modal .model-body-terms-sub{
  max-width: 98%;
  margin: auto;
}
.ss-emp-form-head-sub p{
  letter-spacing: -0.27px;
}
.ss-statement-binder{
  margin-top: -3px;
}
.ss-statement-binder .breadcrumb{
  padding-top: 3px;
}
.manage-account-sub .clickable-txt{
  margin-top: 7px;
}
.lt-space-015{
  letter-spacing: -0.15px!important;
}
.manage-account-wrapper .mng-acnt{
  padding-bottom: 10px;
}
.buttn-text{
  font-size: 14px!important;
  letter-spacing: -0.15px!important;
}

.edit0terms-mdl{
  max-width: 89%;
  margin: auto;
  padding-bottom: 35px;
}
.withdraw-savings{
  max-width: 89%;
  margin: auto;
}