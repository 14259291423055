.bg-for-box {
  width: 400px;
  height: 378px;
  padding-top: 8px 43px 35px;
  border-radius: 10px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
}
.bdr-hr {
  background-color: #d8d8d8;
  max-width: 205px;
  margin: auto;
  height: 1px;
}
.bg-for-box-sub {
  text-align: center;
  max-width: 82%;
  margin: auto;
}
.inter-err-text p {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  text-align: center;
}
.blac-prm {
  color: #2d2d2d;
}
.inter-err-text {
  padding-top: 22px;
  padding-bottom: 15px;
}
.inter-es-text {
  max-width: 68%;
  margin: auto;
  padding-top: 17px;
}
.btn-for-interrupt {
  padding-top: 24px;
}
.interupt-screen {
  display: flex;
  justify-content: center;
}
