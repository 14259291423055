/* ipad minimum media quiriy */
@media (min-width: 768px) {
 
}

/* ipad media query */
@media (min-width: 768px) and (max-width: 1023px) {
  .ss-dashboard .dashboard-scss-btns{
    max-width: 91%;
    margin: auto;
  }
  .pause-mnt-modal {
    max-width: 73%!important;
    margin: auto;
}
  .terms-cons-modal{
    max-width: 73%!important;
    margin: auto;
  }
  .ss-dashboard .ss-dasboard-wrapper {
    max-width: 66.5%;
  }
  .mobile-interchange {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-us-page {
    text-align: center;
  }

  .abs-maxing {
    margin-left: 0;
  }

  .abs-sect-1 {
    width: 100%;
    max-width: 92%;
    margin: auto;
  }

  .mobile-order-1 {
    order: 2;
  }

  .abs-sec-left-binder {
    padding-top: 12px;
    max-width: 100%;
  }

  .abs-bg-text {
    top: 30%;
  }
  .abs-sec-img-con {
    width: 66%;
  }
  .mobile-revert-interchange {
    display: block;
  }

  .bottom-space-abs {
    display: block;
    width: 100%;
  }

  .savings-abs-bdy {
    max-width: 100%;
  }

  .abs-img-wrapd img {
    width: 100%;
    height: 100%;
  }
  .bg-imagae-plants {
    margin-right: 0;
  }

  .slider-maxy {
    max-width: 100%;
  }

  .abs-maxing {
    padding-top: 0;
  }

  .abs-img-wrap {
    margin-top: 0px;
  }

  .abs-img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .savings-abs-bdy {
    padding-bottom: 13px;
  }

  .collect-xra-img {
    width: 100%;
  }

  .collect-xra-img img {
    width: 62%;
    height: 100%;
  }

  .savings-abs-bdy {
    max-width: 94%;
    margin: auto;
  }

  .bgs {
    max-width: 94%;
    margin: auto;
  }


  .abs-bg-text p {
    font-size: 41px;
  }

  .maximising-the-width {
    max-width: 96%;
    margin: auto;
  }
}

/* ipad pro media query */
/*Ipad pro media*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .pause-mnt-modal {
    max-width: 40%!important;
    margin: auto;
}
.ss-dashboard .dashboard-scss-btns{
  margin: 0!important;
}
}