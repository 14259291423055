html {
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

#root>main {
  min-height: 92.5vh;
  background-color: #f6f6f6;
}
.accounts-container{
  height: auto;
  max-width: 1440px;
  margin: auto;
}
ul {
  list-style: none;
}
a{
  color: unset;
}
a:hover{
  color: #00b451;
}
/* accounts landing page css */
/* .accounts-lp-sub-binder{
  background-color: #f6f6f6;
} */
.accountsHeader-binder{
  position: fixed;
  width: 100%;
  z-index: 999;
  max-width: 1440px;
}
.all-container{
  padding-top: 71px;
}
.ac-lp-body{
  min-height: 78.1vh;
  max-width: 800px;
  margin: auto;
}
.ac-lp-sub-head p{
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #212322;
}
.accounts-landing-setup-page .ac-lp-head{
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}
.accounts-landing-setup-page .ac-epoints{
  text-align: center;
}
.accounts-landing-setup-page .top-text{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.27px;
  text-align: center;
  color: #212322;
  padding-bottom: 3px;
}
.accounts-landing-setup-page .accounts-epoints-balance{
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 11px;
  padding-bottom: 14px;
}
.all-links-accounts-binder{
  padding-top: 25px;
}
.accounts-landing-setup-page .all-links-accounts-binder-sub{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
}
.accounts-landing-setup-page .ac-link-card-binder{
  border-radius: 10px;
  background-color: #ffffff;
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  cursor: pointer;
}
.ac-link-card-binder .ac-link-text p{
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.39px;
  color: #212322;
  max-width: 60%;
}
.removed-margins{
  margin-right: 0!important;
}
.accounts-landing-setup-page .flexing-set{
  display: flex;
  margin: auto;
  align-items: center;
  padding: 20px;
  gap: 20px;
}
/* activity page css */
.tooling .place-bottom::after{
  border-bottom-color: white!important;
}
.activity-bg-color {
  background-color: #f6f6f6;
  padding-top: 42px;
  padding-bottom: 49px;
}
.activity-page-table-binder-through-accounts{
  max-width: 1000px;
  margin: auto;
  background-color: white;
}
.page-heads-title-for-all-accounts{
  text-align: center;
}
.page-heads-title-for-all-accounts-content{
  padding-top: 8px;
}
.page-heads-title-for-all-accounts-content p{
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  text-align: center;
  color: #212322;
}
.back-customise p{
  letter-spacing: -0.62px;
  font-size: 20px;
}
.dropdown-for-activity-sub-binder{
  padding-bottom: 6px;
}
.dropdown-for-activity{
  padding-top: 26px;
}
.dropdown-for-activity-sub-binder p{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #212322;
  padding-left: 4px;
}
.dropdown-for-activity-componet{
  padding-bottom: 12px;
}
.maximising-the-width{
  max-width: 920px;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 35px;
}
/* accounts profile */
.AccountsProfile-acc-page{
  max-width: 1000px;
  margin: auto;
  background-color: white;
  padding-bottom: 15px;
}
.AccountsProfile-layout{
  padding-top: 3%;
}
.AccountsProfile-layout{
  padding-top: 42px;
  padding-bottom: 49px;
}
.accounts-profile-binder{
  padding-top: 4px;
}
/* drop down css global */
.accounts-container .ui.dropdown{
  width: 21%;
  height: 42px;
  padding: 10px 10px 13px 11px;
  border-radius: 4px;
  border: solid 1px #ced4da;
  background-color: #ffffff;
  display: flex!important;
  justify-content: space-between;
  align-items: center;
}
.accounts-container .ui.dropdown .menu{
  width: 100%;
}
.accounts-container .ui.dropdown>.dropdown.icon{
  width: 1.23em!important;
}
.order-pb{
  padding-bottom: 50px;
}
/* accounts preference page */
.market-page-pref{
  padding-top: 25px;
}
.set-pref{
  background-color: white;
    max-width: 1000px;
    margin: auto;
    border-radius: 11px;
    margin-top: 42px;
    padding-top: 30px;
    padding-bottom: 21px;
}
.ui.modal{
  border-radius: 10px!important;
}
/* orders page */
.ui.breadcrumb .icon.divider{
  margin-left: 6px;
  margin-right: 7px;
}
.ac-orders-binder{
  padding-bottom: 24px;
}
.parent-order .dropdown-for-activity {
  padding-top: 26px;
  padding-left: 37px;
}
.color-greening{
      color: #00b451;
}
.AccountsMembership-layouts{
  padding-top: 26px;
}
.members-ship-paremt{
  padding-bottom:20px;
}
.membership-child{
  padding-bottom: 49px;
}
.members-ship-paremt .child-of-type{
  display: flex;
  justify-content: space-between;
  max-width: 314px;
  margin: auto;
  padding-top: 26px;
}
/* address css */
.addres-btm{
  padding-bottom: 50px;
}
.rebranded-another-address{
  padding-top: 17px;
}
.your-address-main-wrap{
  max-width: 90%;
  margin: auto;
  margin-top: 19px;
}
.addres-btm .set-pref{
  padding-bottom: 30px;
}
.labeling:focus ~ .label-up{
  /* display: none; */
}
/* add new address */
.add-delivery-adrres-modal{
  padding-top: 1px;
}
.add-delivery-adrres-modal .address-checkbox.ui.checkbox input:checked ~ label:after{
  margin-left: 0px!important;
  margin-top: 0px !important;
}
.modal-address-main .address-checkbox.ui.checkbox input:checked ~ label:after{
  margin-left: 0px!important;
  margin-top: 0px !important;
}
.click-outside-floating-label{
  /* display: none; */
}
.label-for-check {
  padding-top: 0!important;
}
.modal-address-main .modal-cross svg{
  width: 47px;
  height: 25px;
  object-fit: contain;
}
.modal-address-main .modal-cross{
  top: auto;
}
.modal-address-main .editing-adress-container{
  max-width: 99% !important;
}
.parent-order .labeling{
  border: 1px solid #ced4da !important;
}
.crossing-icon-modal {
  float: right;
  display: flex;
  position: relative;
  right: 9px;
  top: 8px;
  font-weight: 100;
  font-size: 21px;
  /* opacity: 0.4; */
  cursor: pointer;
}
.crossing-icon-modal:hover {
  /* opacity: 1; */
  color: #00b451;
  cursor: pointer;
}
/* bread crumbs css */
.ui.breadcrumb a.section.parent-breadcrumbs{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #757575!important;
}
.ui.breadcrumb a{
  color: #757575!important;
}
.ui.breadcrumb a.section{
  color: #212322!important;
}
.section{
  font-size: 14px;
  letter-spacing: -0.27px;
  color: #212322!important;
}
.child-breadcrumbs{
  cursor: none!important;
  pointer-events: none!important;
}
.breadcrumb{
  padding-top: 7px;
}
.accounts-profile-all-content-binder .labeling{
  border: 1px solid #ced4da !important;
}
/* Toast message */
.accounts-body-binder{
  position: relative;
}
.messag-flex-toast{
  position: absolute;
  top: -5px;
  right: 13px;
}
.tost-rel{
  position: relative;
}
.toast-img{
  position: absolute;
  z-index: 999;
  top: 11px;
  left: 15px;
}
.ui.message{
  width: 300px;
  max-width: 300px;
  min-height: 51px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 40%);
  background-color: #00b451;
}
.toast-message p{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  color: #ffffff;
}
.toast-message .content{
  padding-left: 34px;
}
.accounts-profile-all-content-binder .buttn_disabled {
  cursor: not-allowed;
  background-color: #b2b1b1 !important;
  background: #b2b1b1 !important;
  color: white !important;
}
.buttn_disabled {
  cursor: not-allowed;
  background-color: #b2b1b1 !important;
  background: #b2b1b1 !important;
  color: white !important;
}
/* donations */
.black-for-accounts{
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  text-align: center;
  color: #212322;
}
.doantions-container{
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 1000px;
  margin: auto;
  margin-top: 42px;
}
.donation-head-img{
  text-align: center;
  padding-bottom: 5px;
}
.header-donations{
  padding-top: 30px;
  text-align: center;
}
.child-trees{
  text-align: center;
}
.donate-trees-sub-binder{
  padding-top: 24px;
}
.donate-trees{
  display: none;
}
.black-for-ac{
  color: #212322;
}
.text-donated{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  text-align: center;
}
.no-of-trees{
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: -0.58px;
  text-align: center;
  padding-top: 4px;
}
.color-greeny{
  color: #00b451;
}
.trees-donates{
  padding-top: 12px;
}
.text-to-planet p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  text-align: center;
}
.text-to-planet{
  padding-top: 28px;
  padding-bottom: 19px;
}
.donate-payment-binder{
  max-width: 617px;
  margin: auto;
}
.donate-input input{
  width: 313px;
  height: 40px;
  padding: 7px 15px 9px 10px;
  border-radius: 3px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
.donate-input{
  margin-left: 18px;
}
input:focus-visible {
  border: 1px solid #00b451!important;
}
.donate-inputs{
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-donate-btn{
  text-align: center;
  padding-top: 23px;
  padding-bottom: 18px;
}
.component-for-payment-to-donate{
  padding-bottom: 35px;
}
.donations-wrap{
  padding-bottom: 61px;
}
.bottom-sattached-text{
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.16px;
  margin: auto;
  color: #4a4a4a;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  max-width: 83%;
}
.fonts-sixteens{
  font-size: 16px;
}
.trees-counts{
  font-size: 27px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: center;
  color: #00b451;
  margin-top: 0.5rem!important;
}
.succes-donateions{
  text-align: center;
  padding-top: 21px;
}
.donate-act{
  margin-top: 24px;
}
.donate-act p{
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin: auto!important;
  max-width: 34rem;
  margin-bottom: 29px!important;
}
.scss-btns-binder{
  padding-bottom: 11px;
}
.scss-btns-binder.customised-btn-cn{
  margin-left: 14px;

}
.donations-scss-btns{
  padding-bottom: 38px;
}
.planted-static{
  padding-top: 11px;
}
/* payments */
.pd-t-black-ac{
  padding-top: 11px;
}
.static-donate-payment{
  max-width: 1000px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 42px;
}
.sdp-binder{
  padding-top: 32px;
}
.options-payment-text p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  text-align: center;
}
.options-payment-text{
  padding-top: 23px;
  max-width: 37%;
  margin: auto!important;
}
.payment-st-images{
  max-width: 38%;
  margin: auto;
  margin-top: 27px;
  padding-bottom: 31px;
}
.sdp-sub-img{
  display: flex;
    justify-content: space-around;
    align-items: center;
}
.visa-img{
  padding-top: 7px;
}
/* work profile */
.sdp-head{
  text-align: center;
}
.wpf{
  max-width: 1000px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 42px;
}
.company-logo-url-head-wpf-sub-binder img{
  max-width: 113px;
  max-height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-logo-url-head-wpf-sub-binder{
  max-width: 160px;
  margin: auto;
  margin-top: 26px;
  width: 160px;
  min-height: 160px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background-color: #e3e3e3;
}
.wpf-dynamica-values{
  max-width: 510px;
  margin: auto;
  margin-top: 29px;
}
.dyn-wpf-values{
  padding-bottom: 11px;
}
.hr-inside{
  height: 1px;
  margin: 20px 0 18px;
  background-color: #e0e0e0;
}
.work-profile-page{
  padding-bottom: 50px;
}
.dyn-wpf-values .black-for-ac{
  line-height: 23px;
}
.fonting-thrity{
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
}
.fonting-forten{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.27px;
}
.flex-for-static-wpf{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-left: 64px;
  margin-right: 0;
}
.left-content-wpf-static{
  width: 57%;
}
.right-content-wpf-static{
  width: 100%;
}
.regulr-wpf-head{
  padding-top: 6px;
}
.wpf-stc-left-con-bottom .fonting-thrity{
  line-height: 35px;
  max-width: 100%;
}
.wpf-stc-left-con-bottom{
  padding-top: 30px;
}
.wpf-linked-text{
  padding-top: 12px;
}
.dynamic-data-binder{
  padding-bottom: 32px;
}
.static-page-bgs{
  background-color: white;
}
/* static pages */
.static-page-bgs h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.static-page-bgs h1 {
  font-size: 36px!important;
  margin: 0;
}
.static-page-bgs h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.42857;
  color: inherit;
}
.static-page-bgs p{
  font-size: 16px;
  margin: 0 0 10px;
}
.static-page-bgs {
  color: #2d2d2d;
  font-size: 16px;
}
.static-page-bgs ul li{
  color: #2d2d2d;
  font-size: 16px;
}
.static-page-bgs li{
  color: #2d2d2d;
  font-size: 16px;
  line-height: 1.4;
}
.sub-static{
  background-color: white;
  max-width: 1171px;
  margin: auto;
  padding-top: 1px;
}
.static-page-bgs ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
}
.static-page-bgs th {
  text-align: left;
}
.static-page-bgs table{
  border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-color: grey;
    display: table;
}
.static-page-bgs table tr{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: grey;
}
.static-page-bgs table tr td{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: grey;
}
.static-page-bgs a{
  font-size: 16px;
}

/* faq */
.faq-head p{
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #2d2d2d;
}
.faq-head{
  padding-bottom: 14px;
}
.faq-body{
  padding-bottom: 48px;
}
.faq .ui.accordion .accordion .active.title .dropdown.icon, .ui.accordion .active.title .dropdown.icon{
  transform: rotate(0deg);
}
.ac-faq-drp-sub-binder{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.faq .icons-for-collapse{
  width: 12px;
    height: 12px;
    object-fit: contain;
}
.faq .ui.styled.accordion, .ui.styled.accordion .accordion{
  width: 100%;
  box-shadow:none;
}
.faq-sub-body{
  border-radius: 3px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}
/* contact-us */
.Contact-us-head p{
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #2d2d2d;
}
.contac-sub-head p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -0.27px;
  text-align: center;
  color: #2d2d2d;
}
.con-page{
  padding-top: 32px;
  max-width: 50%;
  margin: auto;
}
.contac-sub-head{
  padding-top: 6px;
}
.accounts-container .contact-us .ui.dropdown{
  width: 100%;
}
.contact-drop-down-para{
  padding-bottom: 12px;
}
.contact-form{
  padding-top: 26px;
}
.form-label-p{
  padding-top: 13px;
  padding-bottom: 12px;
}
.input-box-acc textarea{
  min-height: 100px;
    background: transparent !important;
    border-radius: 4px !important;
    /* border-color: #e6e6e6 !important; */
    border: 1px solid #888888 !important;
    font-size: 16px;
    width: 100%;
    padding: 10px;
}
.cont-btn{
  padding-top: 28px;
  padding-bottom: 56px;
  text-align: center;
}
textarea:focus{
  border: 1px solid #00b451 !important;
}
:focus-visible {
  outline: transparent !important;
}
.title .plus-ac{
  display: block;
}
.title .minus-ac{
  display: none;
}
.active.title .minus-ac{
  display: block;
}
.active.title .plus-ac{
  display: none;
}
.faq .ui.styled.accordion .accordion .title, .ui.styled.accordion .title{
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.32px;
  color: #2d2d2d;
}
.faq .answe-p{
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.32px;
  color: #2d2d2d;
}
.ui.selection.active.dropdown:hover{
  border-color: #00b451;
  box-shadow: unset;

}
.ui.selection.active.dropdown{
  border-color: #00b451;
  box-shadow: unset;
}
.ui.selection.active.dropdown:hover .menu{
  border-color: #00b451;
}
.ui.selection.active.dropdown .menu{
  border-color: #00b451;
  box-shadow: unset;
}
.ui.selection.dropdown:focus{
  border-color: #00b451;
}
.sub-statics{
  background-color: white;
  max-width: 1000px;
  margin: auto;
  padding-top: 1px;
}
.con-sucs{
  color: #2d2d2d;
  font-size: 18px;
  text-align: center;
}
.contact-us-success-senario{
  max-width: 577px;
  margin: auto;
  margin-top: 8%;
}
.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text{
  color: #212322;
}

/* join css */
.join-all-container{
  background-color: #ffffff;
  min-height: 78vh;
}
.stepper-join{
  max-width: 499px;
  margin: auto;
  padding-top: 40px;
}
.body-of-join{
  padding-top: 68px;
  max-width: 562px;
  margin: auto;
  padding-bottom: 61px;
}
.mobile-para{
  max-height: 98%;
}
.mobile-para-join-head{
  display: none;
}
.desktop-para-join-head{
  display: block;
}
/* join step 1 */
.join-details-head{
  text-align: center;
}
.text-black-primaryone{
  color: #2d2d2d;
}
.text-orange-primaryone{
  color: #e87200;
}
.join-details-head .size-big-join{
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.7px;
  text-align: center;
}
.size-big-join{
  font-size: 28px;
}
.color-green-primary{
  color: #00b451;
}
.join-details-head .size-small-join{
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
}
.size-small-join{
  font-size: 18px;
}
.font-sixteen{
  font-size: 16px;
}
.head-j-main{
  padding-bottom: 12px;
}
.head-j-sub{
  padding-bottom: 2px;
}
.lear-join{
  padding-top: 5px;
  padding-bottom: 27px;
}
.join-sub-box{
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #f3f1ef;
}
.join-box-sub-head{
  background-color: #f3f1ef;
  padding-top: 9px;
  padding-bottom: 14px;
}
.join-box-body-sub{
  padding-top: 37px;
}
.join-box-body .join-para-pad{
  padding-top: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  max-width: 74%;
  margin: auto;
  padding-bottom: 31px;
}
.join-input-forms{
  max-width: 44%;
  margin: auto;
}
.join-input-forms-sub .labeling{
  border-radius: 3px;
  border: solid 1px #e3e3e3!important;
  background-color: #ffffff;
}
.input-box-acc .default-email-field-uneditable{
  padding-bottom: 2px;
}
.join-btn{
  padding-top: 16px;
}
.join-btn button{
  margin-bottom: 13px;
}
.join-box-body .width-fine{
  max-width: 64%;
  padding-bottom: 27px;
}
.form-sub-conts{
  padding-bottom: 30px;
}
.frm-sb{
  padding-bottom: 40px;
}

.mail-cke a p{
  padding-bottom: 39px;
}
/* join gdpr */
.join-gdpr .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before{
  border: solid 1.8px #e5e5e5!important;
  background-color: #ffffff;
}
.join-gdpr .ui.toggle.checkbox input~.box:after, .ui.toggle.checkbox input~label:after{
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 22%);
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    background: #ffffff;
}
.join-gdpr .terms-conditions-max{
  max-width: 91%;
  margin: auto;
}
.join-gdpr .text-gdpr{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.join-gdpr .text-of-condition{
  max-width: 71%;
  display: flex;
}
.join-gdpr .star{
  padding-right: 7px;
}
.join-gdpr .trs-rows{
  padding-bottom: 24px;
}
.join-gdpr .pb-change{
  padding-bottom: 6px;
}
.join-gdpr .terms-conditions-greay-box{
  background-color: #f2f1ef;
  padding-top: 12px;
}
.join-gdpr .terms-conditions-greay-box-max{
  max-width: 91%;
  margin: auto;
  padding-bottom: 5px;
}
.join-gdpr .terms-conditions-greay-box-max .trs-rows{
  padding-bottom: 15px;
}
.join-gdpr .gdpr-btn-submit{
  padding-top: 15px;
}
.join-gdpr .gdpr-sbmt-btn{
  padding-top: 22px;
}
.join-gdpr .tof{
  max-width: 87%;
}
.join-gdpr .ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after{
  width: 30px;
  height: 30px;
}
.join-gdpr .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before{
  width: 60px;
  height: 30px;
}
.join-gdpr .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before{
  background-color: #00b451!important;
  border: none!important;
}
.join-gdpr .ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after{
  box-shadow: unset;
  border: solid 1px #00b451;
}
.join-gdpr .ui.toggle.checkbox .box:hover::before, .ui.toggle.checkbox label:hover::before{
  background-color: white;
}
/* join pricing */
.join-pricing-details-flex{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.border-underline{
  background-color: #f2f1ef;
  height: 2px;
  margin-top: 11px;
  margin-bottom: 11px;
}
.join-pricing-details{
  max-width: 91%;
  margin: auto;
}
.max-width-for-payment-err{
  max-width: 247px;
  margin: auto;
}
/* join success */
.join-success .join-success-imge{
  text-align: center;
}
.join-success .grey-small-box{
  border-radius: 4px;
  background-color: #f2f1ef;
  max-width: 80%;
  margin: auto;
  margin-top: 11px;
  margin-bottom: 16px;
}
.join-success .grey-small-box p{
  border-radius: 4px;
  background-color: #f2f1ef;
  max-width: 52%;
  margin: auto;
  padding-top: 13px;
  padding-bottom: 17px;
}
.join-success .pb-name{
  padding-top: 3px;
}
.stepper-join .border-green-removed {
  color: #00b451!important;
}
.stepper-join .border-color-changes {
  color: #a4a4a4;
  border: none;
  margin-top: 3px;
  border-top-width: 1px !important;
}
.stepper-join .stepper-child-fixes{
  right: 5px !important;
}
.stepper-join .stepper-last-children {
  padding-right: 32px !important;
}
.sbanner-join-sub-mobile{
  display: none;
}
.sbanner-join-sub{
  display: block;
}
.abs-bg-text p{
  font-size: 59px;
  font-style: normal;
  line-height: 0.81;
  letter-spacing: -2.42px;
}
.banner-join{
  position: relative;
}

/* about us */
.about-us-page{
  background-color: white;
}
.abs-bg-text{
  position: absolute;
  top: 33%;
  width: 100%;
  text-align: center;
}
.abs-section-binder{
  display: flex;
  justify-content: space-between;
  padding-bottom: 63px;
}
.abs-sect-1{
  width: 50%;
}
.abs-sec-img-con{
  width: 50%;
}
.abs-maxing{
  margin-left: 9.5%;
  margin-right: 0;
  padding-top: 57px;
}
.abs-bdy{
  padding-top: 50px;
}
.pb-27{
  padding-bottom: 11px;
}
.pb-35{
  padding-bottom: 24px;
}
.abs-sec-left-binder{
  max-width: 89%;
  margin-right: auto;
}
.abs-img-wrap{
  margin-top: -79px;
}
.abs-img-wrap img{
  width: 597.8px;
  height: 490.7px;
  object-fit: contain;
}
.abs-img-wraps img{
  width: 72%;
  height: 399px;
  object-fit: contain;
}
.savings-abs-bdy{
  max-width: 87%;
  padding-bottom: 69px;
}
.txt0-btm{
  padding-bottom: 11px;
}
.abs-sect-bg{
  /* background-image: url('/static/Thanks-Plane-Desktop.gif');
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain; */
}
.abs-sect-bg{
  padding-bottom: 110px;
}
.bg-tress{
  max-width: 69%;
}

.abs-img-wrapd img{
  width: 564px;
  height: 424px;
}
.one-check-extra-points-binder{
  padding-bottom: 23px;
}
.collect-xtra{
  width: 55.4%;
}
.collect-xra-img{
  width: 40%;
}
.bg-imagae-plants{
  position: relative;
}
.giffy-image-abs{
  position: absolute;
  top: auto;
  bottom: 0;
}
.bgs{
  position: relative;
}
.bg-imagae-plants{
  position: relative;
  margin-right: 10.2%;
}
.donations-landing-page .abs-img-wrap img{
  width: 480px;
  height: 377px;
  object-fit: contain;
}
.donations-landing-page .abs-img-wrap{
  margin-top: 0;
}
.Thank-You-Plane-Mobile{
  display: none;
}
.Thank-You-Plane-desktop{
  display: block;
}
.slider-abs-sub{
  text-align: center;
  padding-top: 60px;
  padding-bottom: 19px;
}
.abs-slick-author{
  padding-top: 24px;
}
.abs-slick-text{
  text-align: center;
}
.slider-maxy{
  max-width: 77%;
  margin: auto;
  margin-bottom: 66px;
}
.abs-slide-max{
  max-width: 88%;
  margin: auto;
}
.slick-dots{
  bottom: -25px;
}
.abs-slide-max .pd-change{
  padding-top: 10px;
}
.about-us-page .slick-dots li.slick-active button:before{
  color: #212322;
}
.about-us-page .slick-dots li button:before{
  font-size: 53px;
  content: '-';
}
/* doantions */
.donations .abs-maxing{
  max-width: 85.7%;
  margin: auto!important;
  margin-right: 0;
}

.donations .abs-sec-left-binder {
  max-width: 80%;
  margin-right: auto;
}
.donations .don-sec-left-binder{
  max-width: 88%;
}
.donations .dos-sec{
  max-width: 87%;
}

.donations-landing-page{
  background-color: white;
}
.ep-dons-static .epoints-price-price{
display: none;
}
.ep-dons-static .epoints-price-dot{
  display: none;
}
.ep-dons-static{
  display: flex;
  align-items: center;
}
.tress-number{
  padding-left: 3px;
    padding-top: 4px;
}
.donations .little-space{
  padding-left: 4px;
}
.donations .position{
  position: relative;
    top: 1px;
}
.flexing-for-social-media{
  display: flex;
  /* justify-content: space-evenly; */
  max-width: 47%;
  margin: auto;
  margin-left: 0;
  align-items: center;
}
.donations .social-images{
  padding-right: 21px;
}
.donations .savings-abs-bdy{
  max-width: 100%;
  text-align: center;
}
.donations .count-card{
  width: 123px;
  height: 168px;
  margin-right: 6px;
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #f4f4f4;
  background-color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}
.donations .coutn-of-trees{
  padding-bottom: 32px;
  padding-top: 11px;
}
.donations .count-card .pt{
  font-size: 105px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  color: #00b451;
}
.donations .sub-count-of-trees{
  display: flex;
  justify-content: center;
  /* max-width: 46%; */
  margin: auto;
}
.atchd-p{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
}
.attached-p{
  position: absolute;
  bottom: -36px;
  width: 203px;
  left: auto;
  right: 0px;
}
.svg-bg{
  position: absolute;
    bottom: -6px;
}
.dons-abs-change{
  display: flex;
  justify-content: center;
}
.what-next-pad{
  padding-top: 35px;
}
.change-for{
  padding-bottom: 50px;
}
.mobile-svg-bg{
  display: none;
}
.svg-bg{
  display: block;
}

/* climatechange success */
.climate-change-success .leafy{
  position: relative;
  margin: auto;
  margin-right: 0;
  text-align: center;
}
.climate-change-success .leafy-img{
  padding-left: 11.5%;
}
.climate-change-success .leafy-img-text{
  position: absolute;
  top: 39%;
  left: 0%;
  width: 100%;
}
.climate-change-success .leafy-img-text p{
  line-height: 1.04;
}
.climate-change-success .donate-act{
  max-width: 24%;
  margin: auto;
  margin-top: 24px;
}
.bg-btm-clim{
  position: relative;
    top: 6px;
}
.leafy-img{
  display: block;
}
.leafy-img-mobile{
  display: none;
}
/* climate failure */
.flexing-foer-err-page{
  display: flex;
    justify-content: center;
    align-items: center;
}
.left-hr{
  border-top: #E6E6E6 1px solid;
  width: 25%;
}
.img-for-failure{
  width: 25%;
}
.right-hr{
  border-top: #E6E6E6 1px solid;
    width: 25%;
}
.climate-failure-para{
  padding-top: 14px;
}
.pading-decrease-failure{
  padding-bottom: 9px;
}
.failure-cliomate-btn{
  padding-top: 10px;
}
.ClimateChangeFailureScreen{
  min-height: 78vh;
}
.donations .countcard-for-comma{
  width: auto;
    background-color: transparent;
    box-shadow: none;
    border: none;
}
.fonting-nineteen{
  font-size: 19px;
}
.epoints-logo-holding{
  padding-bottom: 26px;
}

/* ecards */
.ecards-prints{
  background-color: white;
  min-height: 78vh;
}
.ecards-print-binder{
  max-width: 62%;
  margin: auto;
}
.account-ecards-print{
  margin: auto;
}
.redemable-rewards-wrap{
  max-width: 56%;
  margin: auto;
}
.redemable-rewards{
  background-color: white;
  min-height: 78vh;
}

/* need to move to componet */
.redemable-rewards .items-image-wraper{
flex-basis: 109px;
background-color: #fff;
width: 7vw;
border: 1px solid #e3e3e3;
text-align: center;
}
.redemable-rewards .items-image-text-binder {
  display: flex;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding-top: 23px;
  padding-bottom: 28px;
}
.redemable-rewards .item-img{
  padding: 17px 7px 17px;
    min-height: 133px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.redemable-rewards .items-text-wraper {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 100%;
  max-width: 32%;
}

/* end move to componet */



.redemable-rewards .rdm-rr-sub-card{
  border-top: 1px solid #bfbfbf;
    padding-top: 11px;
}
.redemable-rewards .rdm-rr-head{
  padding-bottom: 10px;
}
.chosse-adres{
  padding-bottom: 8px;
}
.rdm-rr-adres{
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  padding-top: 13px;
  padding-bottom: 13px;
}
.bitton-flex-rdm{
  display: flex;
  padding-top: 19px;
  padding-bottom: 20px;
}
.order-rdm-btn{
  margin-left: 12px;
}
.eacrd-recieced{
  background-color: white;
  min-height: 73vh;
}
.erc{
  max-width: 1000px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: auto;
}
.ecr-img-message p{
  text-transform: capitalize;
}
.printable-head{
  display: flex;
  padding-bottom: 11px;
  align-items: center;
}
.flexing-print-from{
  padding-left: 4px;
}
.flex-total-print{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 17px;
}
.print-btn{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 17px;
  padding-top: 11px;
}
.font-16{
  font-size: 16px;
}
.rdmed-rr .rdm-rr-head{
  border-bottom: 1px solid #bfbfbf;
}
.rdmed-rr .footer-for-rdmeed{
  border-top: dashed #bfbfbf;
  padding-top: 21px;
  margin-top: 26px;
}
.footer-for-rdmeed{
  max-width: 56%;
  margin: auto;
}
.removing-btm-border{
  border-bottom: none!important;
}
.footer-for-rdmeed-order-full{
  border-top: dashed #bfbfbf;
    padding-top: 21px;
    margin-top: 26px;
}
.flex-for-rdmd-gft{
  display: flex;
  align-items: center;
  padding-top: 15px;
}
.rdmed-rr{
  max-width: 56%;
  margin: auto;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 16px;
}
.rdmd-status-text{
  padding-left: 12px;
}
.EXCHANGE-points{
  background-color: white;
  min-height: 73vh;
}
.plf{
  padding-left: 2px;
}
.claiming-sub .epoints-price-text{
  display: none;
}
.claiming-sub .epoints-price-dot{
  display: none;
}
.claiming-sub .epoints-price-epoints{
  display: block;
}

/* Business Gdpr */
.busines-gdpr .join-details-sub-binder{
  padding-top: 68px;
  max-width: 562px;
  margin: auto;
  padding-bottom: 61px;
}
.busines-gdpr{
  background-color: white;
}
.err-case .adres-err{
  max-width: 56%;
  margin: auto;
  margin-bottom: 13px;
}
.moment-space{
  padding-left: 3px;
  padding-right: 3px;
}
.rdmd-status-text .size-small-join {
  font-size: 14px;
}
.flexinfsuccess-gft{
  display: flex;
  align-items: center;
}
.success-rext-gree{
  padding-left: 10px;
}
.uat-col-pt{
  max-width: 78%;
}
.slider-for-abs .slick-dots li{
  width: 12px;
}
.taost-bg-green{
  background: #00b451!important;
  color: white!important;
  border-radius: 4px!important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4)!important;
  width: 300px!important;
  min-height: 51px!important;
  min-height: 51px!important;
}
.intr-screen{
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background-color: white;
}
.spin-logo{
  left: 0;
}
.section.go-back{
  display: none!important;
}
.add-delivery-adrres-modal .address-checkbox.ui.checkbox input:checked ~ label:after{
  margin-left: 1px!important;
  margin-top: 0px !important;
}
.modal-address-main .address-checkbox.ui.checkbox input:checked ~ label:after {
  margin-left: 1px!important;
  margin-top: 1px !important;
}
.color-green-primary{
  color:green;
}