/* zoom issues for mbile zooming the application */
@media screen and (min-width: 1px) and (max-width: 79px) {

}
/* mobile media queries */
@media screen and (min-width: 1px) and (max-width: 767px) {
/* save from salary mobile code */
.input-autotab {
    width: 100px!important;
}
/* dashboard-mobil */
.hadnle-btns-mng{
    max-width: 63%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
}
.hadnle-btns-mng .pause-cons-btn{
    width: 100%!important;
    -webkit-order: 2;
    order: 2;
    text-align: center;
}
.hadnle-btns-mng .pause-cons-btn .buttn{
    width: 100%!important;
}
.hadnle-btns-mng .prm-confirm-btn .buttn{
    width: 100%!important;
}
.hadnle-btns-mng .prm-confirm-btn{
    padding-bottom: 14px;
    padding-left: 0!important;
    width: 100%!important;

}
.scss-btns-binder.customised-btn-cn{
   margin-left: 0px;
   margin-top: 3px;
}
.modify-pot-para {
    text-align: center!important;
}
.manage-account-wrapper .warn-text{
    max-width: 97%;
    margin: auto;
}
.prm-confirm-btn{
    padding-left: 46px;
}
.pause-cons-btn .buttn{
    width: 130px!important;
}
.pause-mnt-modal{
max-width: 100%;
}
.pause-mdl-sub-heed{
    text-align: left;
}
.ss-dasboard-wrapper{
    max-width: 94%;
}
.ss-dashboard .dashboard-scss-btns{
    display: block!important;
    max-width: 64%;
    margin: auto;
}
.ss-dashboard .dashboard-scss-btns .buttn{
    width: 100%!important;
}
.terms-cons-modal{
max-width: 100%;
}
.ss-emp-form-head{
    max-width: 94%;
    margin: auto;
  }
.table-desk-ac{
    max-width: 97%;
    margin-left: 6px;
    margin-top: 24px;
}
.table-desk-sub-binder{
    max-width: 96.6%;
}
    .Toastify__toast-container{
        width: 100%;
        margin: auto;
    }
    .taost-bg-green{
        display: flex;
        justify-content: center;
        width: 94%!important;
        margin: auto;
        margin-top: 11px;
    }

    .taost-bg-red{
        display: flex;
        justify-content: center;
        width: 94%!important;
        margin: auto;
        margin-top: 11px;
    }

    .footer-for-rdmeed{
        max-width: 100%;
        margin: auto;
        padding-left: 11px;
    }
    .what-next-pad{
        padding-top: 12px;
    }
    .u-earth-mobile{
        max-width: 76%;
        margin: auto;
        padding-top: 12%;
    }
    .abs-bg-text{
        top: 22%; 
    }
    .abs-bg-text p{
        max-width: 84%;
        margin: auto;
        line-height: 1.01;
    }
    .uat-spacoinf{
        padding-bottom: 22px;
        max-width: 96%;
        margin: auto;
    }
    .ac-ecr-img-mobile{
    text-align: center;
    min-height: 197px;
    display: flex;
    justify-content: center;
    }
    .redemable-rewards-wrap{
        max-width: 95%;
    }
    .print-btn{
        display: none;
    }
    .claiming-sub{
    position: absolute;
    right: 6%;
    top: 15%;
    }
    .rdmed-rr{
        max-width: 95%;
    }
    .rdmed-rr .footer-for-rdmeed{
        text-align: center;
        padding-top: 21px;
    }
    .printable-head{
        display: none;
    }
    .ecards-print-binder{
        max-width: 95%;
    }
    .ac-ecr-img-mobile img{
        object-fit: contain;
    }
    .epoints-logo-holding{
        padding-bottom: 26px;
        position: absolute;
        top: 19%;
        width: 100%;
        left: 0;
    }
    .doantions-container{
        max-width: 93%;
        margin: auto;
    }
    .donations-wrap{
        background-color: white;
        padding-bottom: 61px;
    }
    .slider-maxy{
        max-width: 100%;
        text-align: center;
    }
    .climate-change-success .donate-act {
        max-width: 100%;
        margin: auto;
        margin-top: 24px;
        position: relative;
        z-index: 2;
    }
    .donations-scss-btns {
        position: relative;
        z-index: 2;
    }
    .leafy-img{
        display: none;
    }
    .leafy-img-mobile{
        display: block!important;
      }
    .mobile-svg-bg{
        display: block;
        position: absolute;
        bottom: -6px;
    }
    .modal-dons-btns .buttn{
        width: 146px!important;
    }
    .model-dons .size-big-join{
        font-size: 22px;
    }
    .modal-don-main .modal-cross{
        right: 0;
        top: 13px;
    }
    .modal-dons-btns{
        max-width: 90%;
        justify-content: space-between;
    }
    .you-abt {
        max-width: 100%;
    }
    .donated-plants-cost {
        max-width: 97%;
    }    
    .svg-bg{
        display: none;
      }
    .donations .count-card{
        width: 53px;
    height: 80px;
    }
    .donations .count-card .pt{
        font-size: 57px!important;
    }
    .flexing-for-social-media{
        display: flex;
        justify-content: center;
        max-width: 100%;
        /* margin-left: 0; */
        align-items: center;
        padding-bottom: 36px;
    }
    .donations .mobile-order-1{
        order: 0;
    }
    .donations-landing-page .p-top{
        padding-top: 45px;
    }
    .donations-landing-page .abs-sec-img-con p{
        text-align: center!important;
    }
    .donations .little-space{
        position: absolute;
        top: 27px;
        text-align: center;
    }
    .donations .ep-dons-static {
        display: flex;
        align-items: center;
        position: relative;
        /* text-align: center; */
        justify-content: center;
    }
    .donations .abs-section-binder {
        padding-bottom: 12px;
    }
    .donations .abs-maxing {
        max-width: 95%;
        margin: auto!important;
        margin-right: auto;
    }
    .donations .abs-sect-1{
        max-width: 100%;
    }
    .donations .abs-sec-left-binder{
        max-width: 100%;
        padding-top: 19px;
    }
    .donations-landing-page .abs-img-wrap img{
        width: 100%;
        height: 100%;
    }
    .Thank-You-Plane-Mobile{
        display: block;
      }
      .Thank-You-Plane-desktop{
          display: none;
      }
    .bg-imagae-plants{
        position: relative;
        margin-right: 0;
    }
    .bgs{
        max-width: 92%;
        margin: auto;
    }
    .abs-section-binder{
        padding-bottom: 42px;
    }
    .abs-sect-bg{
        background-size: contain;
        background-position: bottom;
        padding-bottom: 52px;
    }
    .mobile-interchange{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* optional */
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .mobile-order-1{
        -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .abs-bdy{
        padding-top: 15px;
    }
    .abs-sect-1{
        width: 100%;
        max-width: 92%;
        margin: auto;
    }
    .abs-sec-img-con{
        width: 100%;
    }
    .abs-img-wrap{
        margin-top: 0;
    }
    .abs-maxing{
        margin-left: 0;
         margin-right: 0;
       padding-top: 0;
       text-align: center;
    }
    .abs-img-wrap img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .abs-sec-left-binder{
        max-width: 100%;
    }
    .mobile-revert-interchange{
        display: block;
    }
    .abs-img-wraps img{
        width: 100%;
        height: 100%;
    }
    .savings-abs-bdy{
        max-width: 100%;
        max-width: 92%;
        margin: auto;
        padding-bottom: 46px;
    }
    .abs-img-wrapd img {
        width: 100%;
        height: 100%;
    }
    .bottom-space-abs{
        padding-bottom: 23px;
    }
    /* join */
    .mobile-para-join-head{
        display: block;
      }
      .join-btn .buttn{
        width: 247px!important;
      }
      .desktop-para-join-head{
        display: none;
      }
      .join-details .join-btn .buttn{
        width: 100%!important;
      }
     .join-details .join-box-body .buttn{
        width: 100%!important;
      }
    .sbanner-join-sub{
        display: none;
    }
    .sbanner-join-sub-mobile{
        display: block;
      }
    .join-sub-box{
        max-width: 97%;
        margin: auto;
    }
    .join-input-forms {
        max-width: 81%;
        margin: auto;
    }
    .join-box-body .join-para-pad{
        max-width: 77%;
    }
    .join-success .grey-small-box{
        max-width: 100%;
    }
    .join-success .grey-small-box p{
        max-width: 64%;
    }
    .con-page{
        max-width: 93%;
    }
    .faq .mobile-para{
        max-width: 95%;
    }
    .static-page-bgs h1 {
        font-size: 35px!important;
        padding-bottom: 7px;
    }
    .sub-static {
        background-color: white;
        /* max-width: 1171px; */
        margin: auto;
        padding-top: 1px;
        max-width: 93%;
        margin: auto;
    }
    .static-page-bgs ul, ol{
        padding-left: 31px;
    }
    .sdp-binder-mobile-donate {
        padding-top: 35px;
    }
    .wpf-stc-left-con-bottom .fonting-thrity{
        max-width: 99%;
    }
    .wpf-static-img-binder{
        padding-top: 21px;
    }
    .flex-for-static-wpf{
        max-width: 88%;
        margin: auto;
        margin-left: auto;
        margin-right: auto;

        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* optional */
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .left-content-wpf-static{
        -webkit-box-ordinal-group: 3;
        order: 2;
        text-align: center;
        width: 100%;
        padding-bottom: 31px;
    }
    .wpf-dynamica-values{
        max-width: 89%;
    }
    .options-payment-text {
        padding-top: 27px;
        max-width: 87%;
        margin: auto!important;
    }
    .payment-st-images {
        max-width: 100%;   
    } 
    .accounts-container .ui.dropdown>.dropdown.icon{
        width: auto!important;
        right: 13px!important;
    }
    .messag-flex-toast{
        position: absolute;
        top: -5px;
        right: 7px;
        width: 97%;
        max-width: 96%;
        margin: auto;
        }
        .ui.message {
            width: 100%;
            max-width: 100%;
        }
    .market-page-pref{
        padding-left: 14px;
    }
    .dropdown-for-activity-componet{
        margin-left: 5px;
    }
    .dropdown-for-activity{
        padding-bottom: 21px;
    }
    .table-lib{
        margin-top: 21px;
    }
    .parent-order .dropdown-for-activity{
        padding-left: 0px;
        padding-bottom: 14px;
        max-width: 97%;
        margin: auto;
    }
    .set-pref{
        border-radius: 0;
        max-width: 100%;
    }
    .accounts-container .dropping{
        padding: 0!important;
        border: none!important;
    }
    .accounts-container .dropping .dropdown{
        position: absolute;
        left: 0!important;
        top: 0!important;
    }
    .AccountsProfile-layout{
        /* padding-left: 14px; */
    }
    .accounts-container .ui.dropdown .menu{
        width: 100%;
        top: 85%;
    }
    .accounts-container .ui.dropdown>.dropdown.icon{
        top: 13px!important;
    }
    .dropdown-for-activity-sub-binder{
        padding-left: 13px;
    }
    .accounts-container .ui.dropdown{
        width: 97%;
    }
    .accounts-container .dropping .menu{
        left: 0!important;
        right: 0;
    }
    .accounts-landing-setup-page .accounts-epoints-balance{
        border-radius: 0;
    }
    .accounts-landing-setup-page .all-links-accounts-binder-sub{
        display: block;
    }
    .accounts-landing-setup-page .ac-link-card-binder{
        width: 100%;
        max-width: 100%;
    }
    .accounts-landing-setup-page .flexing-set{
        display: flex;
        justify-content: start;
        width: 100%;
        align-items: center;
        gap: 30px;
        padding-left: 40px;
    }
    .accounts-landing-setup-page .you-address-edit .ac-link-image{
        margin-left: 0;
    }
    .accounts-landing-setup-page .mp-positions .ac-link-image{
        position: unset;
    }
    .accounts-landing-setup-page .ac-link-text{
        /* margin-left: 24px; */
    }
    .accounts-landing-setup-page .mp-positions .ac-link-text p{
        position: unset;
    }
    .accounts-landing-setup-page .mp-positions{
        /* margin-left: 12%; */
    }
    .accounts-landing-setup-page .md-changes .flexing-set{
        width: 100%;
        /* margin-left: 14%; */
    }
    .payment-de .ac-link-image{
        margin-left: 0;
    }
    .accounts-landing-setup-page .donate-text{
        position: unset;
    }
    .accounts-landing-setup-page .ac-link-card-binder{
        border-radius: 0;
        margin-bottom: 4px;
    }
    .md-changes .ac-link-text p{
        position: unset;
    }
    .flexing-set .mp-positions{
        /* margin-left: 12%; */
    }
    .mp-positions .ac-link-text{
        /* max-width: 41%; */
    }

 .md-changes .ac-link-text{
        /* margin-left: 30px; */
        /* max-width: 21%; */
    }
    .flexing-set .payment-de .ac-link-text{
        /* margin-left: 4.8%; */
    }
    .dropdown-for-activity-sub-binder p{
        padding-left: 0;
    }
    .dropdown-for-order-sub-binder{
        padding-left: 6px;
    }
    .dropdown-for-order-componet{
        margin-left: 0;
    }
    .parent-order .dropdown-for-order{
        padding-top: 24px;
        padding-bottom: 42px;
    }
    .ac-link-image img {
        height: 40px;
        width: 40px;
        object-fit: contain;
    }
}
/* mobile landscape css */
/* orientation : landscape */
@media only screen and (min-device-width: 480px) and (max-device-width: 812px) and (orientation: landscape) {

}
